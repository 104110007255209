import React from "react";
import styled, { css } from "styled-components";

import { StyledTopBannerWrapper } from "../pricingnew/topbanner";
import { Flex, Image, Heading, Text } from "../../globals/UIKit";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import { mq } from "../../globals/utils";

export const StyledSectionWrapperLightGray = styled(StyledSectionWrapper)`
  background: linear-gradient(0deg, #eef7ff 11.83%, #cfe8ff 100%);
  .pricingPB-200 {
    padding-bottom: 150px;
  }

  ${mq.below.md} {
    .pricingPB-200 {
      padding-bottom: 150px;
    }
  }
`;

const HeroBanner = ({ title, description, subTitle, subTtileDesc }) => {
  return (
    <StyledSectionWrapperLightGray>
      <StyledContainerWrapper>
        <StyledTopBannerWrapper>
          <Flex
            bg="#fff"
            borderRadius="20px"
            justifyContent="center"
            boxShadow="0px 16px 21.3333px -5.33333px rgba(0, 64, 224, 0.29), 0px 5.33333px 8px -2.66667px rgba(0, 58, 204, 0.03)"
            width="80px"
            height="80px"
            mb="24px"
            css={css`
              @media (max-width: 780px) {
                margin-top: 60px;
              }
            `}
          >
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1677772778/main-web/support_velx0i.png"
              alt="hasura-pricing"
              minWidth="52.2px"
              width="53.33px"
            />
          </Flex>
          <Heading
            mb="16px"
            fontSize="30px"
            fontWeight="700"
            color="#1F2A37"
            lineHeight="1.2"
            letterSpacing="-0.01em"
          >
            {title ? title : "Hasura Support plans"}
          </Heading>
          <Text
            mb="32px"
            fontSize="20px"
            fontWeight="400"
            color="#111927"
            lineHeight="1.5"
            letterSpacing="-0.01em"
            width="70%"
            css={css`
              @media (max-width: 960px) {
                width: 85%;
              }
              @media (max-width: 767px) {
                font-size: 18px;
              }
            `}
          >
            {description
              ? description
              : "At Hasura, our aim is to ensure success for our customers. Our support plans are tailored to provide you with an optimal blend of production and developer support, resources and expert guidance, enabling you to achieve success with Hasura Enterprise."}
          </Text>
          <Heading
            mb="16px"
            fontSize="24px"
            fontWeight="700"
            color="#1F2A37"
            lineHeight="1.2"
            letterSpacing="-0.01em"
            width="65%"
            css={css`
              @media (max-width: 960px) {
                width: 85%;
              }
              @media (max-width: 767px) {
                font-size: 18px;
              }
            `}
          >
            {subTitle ? subTitle : "Support Plan Matrix"}
          </Heading>
          <Text
            mb="24px"
            fontSize="20px"
            fontWeight="400"
            color="#111927"
            lineHeight="1.5"
            letterSpacing="-0.01em"
            width="70%"
            css={css`
              @media (max-width: 960px) {
                width: 85%;
              }
              @media (max-width: 767px) {
                font-size: 18px;
              }
            `}
          >
            {subTtileDesc
              ? subTtileDesc
              : "This table shows features available in each of our support plans - Silver, Gold and Platinum. Each time mentioned below indicates the expected duration for a first response. For more information on support severities and the JumpStart program, please refer to the sections following this table."}
          </Text>
          <Heading
            mb="16px"
            fontSize="24px"
            fontWeight="700"
            color="#1F2A37"
            lineHeight="1.2"
            letterSpacing="-0.01em"
            width="65%"
            css={css`
              @media (max-width: 960px) {
                width: 85%;
              }
              @media (max-width: 767px) {
                font-size: 18px;
              }
            `}
          >
            Support Business Hours
          </Heading>
          <Text
            mb="24px"
            fontSize="20px"
            fontWeight="400"
            color="#111927"
            lineHeight="1.5"
            letterSpacing="-0.01em"
            width="70%"
            css={css`
              @media (max-width: 960px) {
                width: 85%;
              }
              @media (max-width: 767px) {
                font-size: 18px;
              }
            `}
          >
            8pm US Pacific Time, Sunday to 6pm US Pacific Time, Friday
          </Text>
          <Heading
            mb="16px"
            fontSize="24px"
            fontWeight="700"
            color="#1F2A37"
            lineHeight="1.2"
            letterSpacing="-0.01em"
            width="65%"
            css={css`
              @media (max-width: 960px) {
                width: 85%;
              }
              @media (max-width: 767px) {
                font-size: 18px;
              }
            `}
          >
            Support Business Days
          </Heading>
          <Text
            mb="24px"
            fontSize="20px"
            fontWeight="400"
            color="#111927"
            lineHeight="1.5"
            letterSpacing="-0.01em"
            width="70%"
            css={css`
              @media (max-width: 960px) {
                width: 85%;
              }
              @media (max-width: 767px) {
                font-size: 18px;
              }
            `}
          >
            Monday - Friday during business hours
          </Text>
        </StyledTopBannerWrapper>
        <div className="pricingPB-200"></div>
      </StyledContainerWrapper>
    </StyledSectionWrapperLightGray>
  );
};

export default HeroBanner;
