import React from "react";
import styled from "styled-components";

import { Image } from "../../globals/UIKit";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import { StyledBody1, StyledDesc3 } from "../shared/StyledTypography";
import { mq } from "../../globals/utils";

export const StyledSectionWrapperHideMobile = styled(StyledSectionWrapper)`
  padding: 0 0;
  ${mq.below.lg} {
    display: none;
  }
`;

export const StyledFeatureTableWrapper = styled.div`
  .wd40 {
    width: 40%;
    margin: 0 0;
  }
  .displayFlex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledTableWrapper = styled.table`
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  position: relative;
  width: 100%;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 12px 16px rgba(28, 38, 63, 0.08), 0px 4px 6px rgba(28, 38, 63, 0.03);
  border-radius: 16px;
  thead,
  tbody {
    tr {
      &:first-child {
        th {
          padding-top: 54px;
        }
      }
      &:last-child {
        td {
          border-bottom: 1px solid transparent;
          padding-bottom: 44px;
        }
      }
      th,
      td {
        padding: 30px 36px;
        border-bottom: 1px solid #e5e7eb;
        width: 19%;
        vertical-align: top;
        &:first-child {
          width: 24%;
          border-right: 1px solid #e5e7eb;
          border-bottom: 1px solid transparent;
        }
        &.removePaddBottom {
          padding-bottom: 0;
        }
        &.removeBorderBottom {
          border-bottom: 0px !important;
        }
        &.pt24 {
          padding-top: 24px;
        }
        .labelTagWrapper {
          .labelTag {
            font-weight: 600;
            font-size: 12px;
            color: #111927;
            padding: 2px 8px;
            margin-right: 10px;
            border-radius: 100px;
            letter-spacing: 0.01em;
            width: 50px;
            min-width: 50px;
            text-align: center;
            line-height: 18px;
            &.red300 {
              background: #fba5a6;
            }
            &.red400 {
              background: #f77173;
            }
            &.orange300 {
              background: #fdb97a;
            }
            &.orange400 {
              background: #fb9147;
            }
          }
        }
        .flexStart {
          display: flex;
          align-items: flex-start;
          &.pb12 {
            padding-bottom: 12px;
          }
        }
      }
    }
  }
`;

export const RightTick = ({ mtr }) => (
  <Image
    src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675197052/main-web/icons/Icon_h9rnq3.svg"
    alt="Tick"
    minWidth="16px"
    width="16px"
    margin={mtr ? mtr : 0}
  />
);

const HasuraCloudFeature = ({ currentTab, bronzePlan }) => {
  return (
    <StyledSectionWrapperHideMobile>
      <StyledContainerWrapper>
        <StyledFeatureTableWrapper>
          {bronzePlan ? (
            <StyledTableWrapper>
              <thead>
                <tr>
                  <th scope="col">
                    <StyledBody1 fontWeight="font_bold">Features</StyledBody1>
                  </th>
                  <th scope="col">
                    <StyledBody1 fontWeight="font_bold">Bronze</StyledBody1>
                  </th>
                  <th scope="col">
                    <StyledBody1 fontWeight="font_bold">Silver</StyledBody1>
                  </th>
                  <th scope="col">
                    <StyledBody1 fontWeight="font_bold">Gold</StyledBody1>
                  </th>
                  <th scope="col">
                    <StyledBody1 fontWeight="font_bold">Platinum</StyledBody1>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <StyledDesc3 variant="neutral_900">Community Support</StyledDesc3>
                  </td>
                  <td>
                    <RightTick />
                  </td>
                  <td>
                    <RightTick />
                  </td>
                  <td>
                    <RightTick />
                  </td>
                  <td>
                    <RightTick />
                  </td>
                </tr>
                <tr>
                  <td>
                    <StyledDesc3 variant="neutral_900">Dev support</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">Ticketed</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">Ticketed</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">Ticketed</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">Ticketed</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <StyledDesc3 variant="neutral_900">Coverage</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">
                      {currentTab === "cloud" ? "24 x 5 (business day)" : "24 x 5 (business day)"}
                    </StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">24x7</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">24x7</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">24x7</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td className="removePaddBottom">
                    <StyledDesc3 variant="neutral_900">First response SLAs</StyledDesc3>
                  </td>
                  <td className="removePaddBottom removeBorderBottom" colSpan="4"></td>
                </tr>
                <tr>
                  <td className="pt24">
                    <div className="labelTagWrapper flexStart">
                      <div className="labelTag red400">Sev 1</div>
                      <StyledDesc3 variant="neutral_900">Critical Impact</StyledDesc3>
                    </div>
                  </td>
                  <td className="pt24">
                    <StyledDesc3 variant="neutral_900">{"<"} 8 hours</StyledDesc3>
                  </td>
                  <td className="pt24">
                    <StyledDesc3 variant="neutral_900">{"<"} 4 hours</StyledDesc3>
                  </td>
                  <td className="pt24">
                    <StyledDesc3 variant="neutral_900">{"<"} 2 hours</StyledDesc3>
                  </td>
                  <td className="pt24">
                    <StyledDesc3 variant="neutral_900">{"<"} 1 hour</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="labelTagWrapper flexStart">
                      <div className="labelTag red300">Sev 2</div>
                      <StyledDesc3 variant="neutral_900">High Impact</StyledDesc3>
                    </div>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 12 business hours</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 8 business hours</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 4 business hours</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 2 business hours</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="labelTagWrapper flexStart">
                      <div className="labelTag orange400">Sev 3</div>
                      <StyledDesc3 variant="neutral_900">Medium Impact</StyledDesc3>
                    </div>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 24 business hours</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 12 business hours</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 8 business hours</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 4 business hours</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="labelTagWrapper flexStart">
                      <div className="labelTag orange300">Sev 4</div>
                      <StyledDesc3 variant="neutral_900">
                        Low Impact or General Guidance or Dev Support
                      </StyledDesc3>
                    </div>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 1 business day</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 1 business day</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 1 business day</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 1 business day</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <StyledDesc3 variant="neutral_900">Onboarding Support</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">N/A</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">N/A</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 className="flexStart pb12" variant="neutral_900">
                      <RightTick mtr="6px 16px 0 0" />
                      <span>Includes 1-time JumpStart program</span>
                    </StyledDesc3>
                    <StyledDesc3 className="flexStart" variant="neutral_900">
                      <RightTick mtr="6px 16px 0 0" />
                      <span>Named CSM</span>
                    </StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 className="flexStart pb12" variant="neutral_900">
                      <RightTick mtr="6px 16px 0 0" />
                      <span>Includes 1-time JumpStart program</span>
                    </StyledDesc3>
                    <StyledDesc3 className="flexStart pb12" variant="neutral_900">
                      <RightTick mtr="6px 16px 0 0" />
                      <span>Named CSM</span>
                    </StyledDesc3>
                    <StyledDesc3 className="flexStart pb12" variant="neutral_900">
                      <RightTick mtr="6px 16px 0 0" />
                      <span>Developer Office hours</span>
                    </StyledDesc3>
                    <StyledDesc3 className="flexStart" variant="neutral_900">
                      <RightTick mtr="6px 16px 0 0" />
                      <span>Training Workshops SKU</span>
                    </StyledDesc3>
                  </td>
                </tr>
              </tbody>
            </StyledTableWrapper>
          ) : (
            <StyledTableWrapper>
              <thead>
                <tr>
                  <th scope="col">
                    <StyledBody1 fontWeight="font_bold">Features</StyledBody1>
                  </th>
                  <th scope="col">
                    <StyledBody1 fontWeight="font_bold">Silver</StyledBody1>
                  </th>
                  <th scope="col">
                    <StyledBody1 fontWeight="font_bold">Gold</StyledBody1>
                  </th>
                  <th scope="col">
                    <StyledBody1 fontWeight="font_bold">Platinum</StyledBody1>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <StyledDesc3 variant="neutral_900">Community Support</StyledDesc3>
                  </td>
                  <td>
                    <RightTick />
                  </td>
                  <td>
                    <RightTick />
                  </td>
                  <td>
                    <RightTick />
                  </td>
                </tr>

                <tr>
                  <td className="removePaddBottom">
                    <StyledDesc3 variant="neutral_900">First response SLAs</StyledDesc3>
                  </td>
                  <td className="removePaddBottom removeBorderBottom" colSpan="4"></td>
                </tr>
                <tr>
                  <td className="pt24">
                    <div className="labelTagWrapper flexStart">
                      <div className="labelTag red400">P 1</div>
                      <StyledDesc3 variant="neutral_900">Critical Impact</StyledDesc3>
                    </div>
                  </td>

                  <td className="pt24">
                    <StyledDesc3 variant="neutral_900">{"<"} 4 hours</StyledDesc3>
                  </td>
                  <td className="pt24">
                    <StyledDesc3 variant="neutral_900">{"<"} 2 hours</StyledDesc3>
                  </td>
                  <td className="pt24">
                    <StyledDesc3 variant="neutral_900">{"<"} 1 hour</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="labelTagWrapper flexStart">
                      <div className="labelTag red300">P 2</div>
                      <StyledDesc3 variant="neutral_900">High Impact</StyledDesc3>
                    </div>
                  </td>

                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 8 business hours</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 4 business hours</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 2 business hours</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="labelTagWrapper flexStart">
                      <div className="labelTag orange400">P 3</div>
                      <StyledDesc3 variant="neutral_900">Medium Impact</StyledDesc3>
                    </div>
                  </td>

                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 12 business hours</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 8 business hours</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 4 business hours</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="labelTagWrapper flexStart">
                      <div className="labelTag orange300">P 4</div>
                      <StyledDesc3 variant="neutral_900">
                        Low Impact or General Guidance or Dev Support
                      </StyledDesc3>
                    </div>
                  </td>

                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 1 business day</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 1 business day</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">{"<"} 1 business day</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <StyledDesc3 variant="neutral_900">Tickets</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">20 per month</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">Unlimited</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">Unlimited</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <StyledDesc3 variant="neutral_900">Minimum # Models by Plan</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">30</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">45</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">60</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <StyledDesc3 variant="neutral_900">
                      Quarterly and Annual Business Reviews
                    </StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">Not Included</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">Included</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">Included</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <StyledDesc3 variant="neutral_900">Account Management</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">No</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">Yes</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">Named CSM</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <StyledDesc3 variant="neutral_900">Onboarding Solutions Architect</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">No</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">Solutions Architect Pool</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">Named Solutions Architect</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <StyledDesc3 variant="neutral_900">Office Hours</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">No</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">No</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 variant="neutral_900">Yes</StyledDesc3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <StyledDesc3 variant="neutral_900">Onboarding Support</StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 className="flexStart pb12" variant="neutral_900">
                      <RightTick mtr="6px 16px 0 0" />
                      <span>Self-guided success plan</span>
                    </StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 className="flexStart pb12" variant="neutral_900">
                      <RightTick mtr="6px 16px 0 0" />
                      <span>JumpStart program</span>
                    </StyledDesc3>
                  </td>
                  <td>
                    <StyledDesc3 className="flexStart pb12" variant="neutral_900">
                      <RightTick mtr="6px 16px 0 0" />
                      <span>JumpStart program</span>
                    </StyledDesc3>
                  </td>
                </tr>
              </tbody>
            </StyledTableWrapper>
          )}
        </StyledFeatureTableWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapperHideMobile>
  );
};

export default HasuraCloudFeature;
