import React from "react";
import styled from "styled-components";

import { StyledBody1, StyledDesc2, StyledDesc3 } from "../shared/StyledTypography";
import { RightTick } from "./hasuracloudfeature";

const StyledFeatureWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 12px 16px rgba(28, 38, 63, 0.08), 0px 4px 6px rgba(28, 38, 63, 0.03);
  border-radius: 16px;
  max-width: 450px;
  margin: 0 auto;
  padding: 32px 24px;
  margin-top: 20px;
  .pb32 {
    padding-bottom: 32px;
  }
  .flexStart {
    display: flex;
    align-items: flex-start;
    &.pb12 {
      padding-bottom: 12px;
    }
    img {
      min-width: 12px;
      width: 12px;
      margin: 6px 8px 0 0;
    }
  }
  .labelTagWrapper {
    padding-bottom: 8px;
    &:last-child {
      padding-bottom: 0;
    }
    .labelTag {
      font-weight: 600;
      font-size: 12px;
      color: #111927;
      padding: 2px 8px;
      margin-bottom: 8px;
      border-radius: 100px;
      letter-spacing: 0.01em;
      width: 50px;
      text-align: center;
      line-height: 18px;
      &.red300 {
        background: #fba5a6;
      }
      &.red400 {
        background: #f77173;
      }
      &.orange300 {
        background: #fdb97a;
      }
      &.orange400 {
        background: #fb9147;
      }
    }
  }
`;

const MobileHasuraCloudFeature = ({ currentTab, bronzePlan }) => {
  return (
    <>
      {bronzePlan ? (
        <StyledFeatureWrapper>
          <div className="pb32">
            <StyledBody1 variant="neutral_900" paddingBottom="pb16" fontWeight="font_bold">
              Bronze
            </StyledBody1>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Community Support
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Dev Support - Ticketed
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Coverage - {currentTab === "cloud" ? "24x5 (business day)" : "24x5 (business day)"}
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              First response SLAs
            </StyledDesc2>
            <div className="labelTagWrapper">
              <div className="labelTag red400">P 1</div>
              <StyledDesc3 variant="neutral_900">Critical Impact {"<"} 8 hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag red300">P 2</div>
              <StyledDesc3 variant="neutral_900">High Impact {"<"} 12 business hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag orange400">P 3</div>
              <StyledDesc3 variant="neutral_900">Medium Impact {"<"} 24 business hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag orange400">P 4</div>
              <StyledDesc3 variant="neutral_900">
                Low Impact or General Guidance or Dev Support {"<"} 1 business day
              </StyledDesc3>
            </div>
          </div>
          <div className="pb32">
            <StyledBody1 variant="neutral_900" paddingBottom="pb16" fontWeight="font_bold">
              Silver
            </StyledBody1>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Community Support
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Dev Support - Ticketed
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Coverage - 24x7
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              First response SLAs
            </StyledDesc2>
            <div className="labelTagWrapper">
              <div className="labelTag red400">P 1</div>
              <StyledDesc3 variant="neutral_900">Critical Impact {"<"} 4 hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag red300">P 2</div>
              <StyledDesc3 variant="neutral_900">High Impact {"<"} 8 business hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag orange400">P 3</div>
              <StyledDesc3 variant="neutral_900">Medium Impact {"<"} 12 business hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag orange400">P 4</div>
              <StyledDesc3 variant="neutral_900">
                Low Impact or General Guidance or Dev Support {"<"} 1 business day
              </StyledDesc3>
            </div>
          </div>
          <div className="pb32">
            <StyledBody1 variant="neutral_900" paddingBottom="pb16" fontWeight="font_bold">
              Gold
            </StyledBody1>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Community Support
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Dev Support - Ticketed
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Coverage - 24x7
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              First response SLAs
            </StyledDesc2>
            <div className="labelTagWrapper">
              <div className="labelTag red400">P 1</div>
              <StyledDesc3 variant="neutral_900">Critical Impact {"<"} 2 hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag red300">P 2</div>
              <StyledDesc3 variant="neutral_900">High Impact {"<"} 4 business hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag orange400">P 3</div>
              <StyledDesc3 variant="neutral_900">Medium Impact {"<"} 8 business hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag orange400">P 4</div>
              <StyledDesc3 variant="neutral_900">
                Low Impact or General Guidance or Dev Support {"<"} 1 business day
              </StyledDesc3>
            </div>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Onboarding Support
            </StyledDesc2>
            <StyledDesc3 className="flexStart pb12" variant="neutral_900">
              <RightTick mtr="6px 16px 0 0" />
              <span>Includes 1-time JumpStart program</span>
            </StyledDesc3>
            <StyledDesc3 className="flexStart" variant="neutral_900">
              <RightTick mtr="6px 16px 0 0" />
              <span>Named CSM</span>
            </StyledDesc3>
          </div>
          <div>
            <StyledBody1 variant="neutral_900" paddingBottom="pb16" fontWeight="font_bold">
              Platinum
            </StyledBody1>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Community Support
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Dev Support - Ticketed
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Coverage - 24x7
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              First response SLAs
            </StyledDesc2>
            <div className="labelTagWrapper">
              <div className="labelTag red400">P 1</div>
              <StyledDesc3 variant="neutral_900">Critical Impact {"<"} 1 hour</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag red300">P 2</div>
              <StyledDesc3 variant="neutral_900">High Impact {"<"} 1 business hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag orange400">P 3</div>
              <StyledDesc3 variant="neutral_900">Medium Impact {"<"} 4 business hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag orange400">P 4</div>
              <StyledDesc3 variant="neutral_900">
                Low Impact or General Guidance or Dev Support {"<"} 1 business day
              </StyledDesc3>
            </div>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Onboarding Support
            </StyledDesc2>
            <StyledDesc3 className="flexStart pb12" variant="neutral_900">
              <RightTick mtr="6px 16px 0 0" />
              <span>Includes 1-time JumpStart program</span>
            </StyledDesc3>
            <StyledDesc3 className="flexStart pb12" variant="neutral_900">
              <RightTick mtr="6px 16px 0 0" />
              <span>Named CSM</span>
            </StyledDesc3>
            <StyledDesc3 className="flexStart pb12" variant="neutral_900">
              <RightTick mtr="6px 16px 0 0" />
              <span>Developer Office hours</span>
            </StyledDesc3>
            <StyledDesc3 className="flexStart" variant="neutral_900">
              <RightTick mtr="6px 16px 0 0" />
              <span>Training Workshops SKU</span>
            </StyledDesc3>
          </div>
        </StyledFeatureWrapper>
      ) : (
        <StyledFeatureWrapper>
          <div className="pb32">
            <StyledBody1 variant="neutral_900" paddingBottom="pb16" fontWeight="font_bold">
              Silver
            </StyledBody1>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Community Support
            </StyledDesc2>

            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              First response SLAs
            </StyledDesc2>
            <div className="labelTagWrapper">
              <div className="labelTag red400">P 1</div>
              <StyledDesc3 variant="neutral_900">Critical Impact {"<"} 4 hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag red300">P 2</div>
              <StyledDesc3 variant="neutral_900">High Impact {"<"} 8 business hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag orange400">P 3</div>
              <StyledDesc3 variant="neutral_900">Medium Impact {"<"} 12 business hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag orange400">P 4</div>
              <StyledDesc3 variant="neutral_900">
                Low Impact or General Guidance or Dev Support {"<"} 1 business day
              </StyledDesc3>
            </div>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Tickets - 20 per month
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Minimum # Models by Plan - 30
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Onboarding Support
            </StyledDesc2>
            <StyledDesc3 className="flexStart pb12" variant="neutral_900">
              <RightTick mtr="6px 16px 0 0" />
              <span>Self-guided success plan</span>
            </StyledDesc3>
          </div>
          <div className="pb32">
            <StyledBody1 variant="neutral_900" paddingBottom="pb16" fontWeight="font_bold">
              Gold
            </StyledBody1>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Community Support
            </StyledDesc2>

            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              First response SLAs
            </StyledDesc2>
            <div className="labelTagWrapper">
              <div className="labelTag red400">P 1</div>
              <StyledDesc3 variant="neutral_900">Critical Impact {"<"} 2 hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag red300">P 2</div>
              <StyledDesc3 variant="neutral_900">High Impact {"<"} 4 business hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag orange400">P 3</div>
              <StyledDesc3 variant="neutral_900">Medium Impact {"<"} 8 business hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag orange400">P 4</div>
              <StyledDesc3 variant="neutral_900">
                Low Impact or General Guidance or Dev Support {"<"} 1 business day
              </StyledDesc3>
            </div>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Tickets - Unlimited
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Minimum # Models by Plan - 45
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Quarterly and Annual Business Reviews - Included
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Account Management - Yes
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Onboarding Solutions Architect - Solutions Architect Pool
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Onboarding Support
            </StyledDesc2>
            <StyledDesc3 className="flexStart pb12" variant="neutral_900">
              <RightTick mtr="6px 16px 0 0" />
              <span>JumpStart program</span>
            </StyledDesc3>
          </div>
          <div className="pb32">
            <StyledBody1 variant="neutral_900" paddingBottom="pb16" fontWeight="font_bold">
              Platinum
            </StyledBody1>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Community Support
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              First response SLAs
            </StyledDesc2>
            <div className="labelTagWrapper">
              <div className="labelTag red400">P 1</div>
              <StyledDesc3 variant="neutral_900">Critical Impact {"<"} 1 hour</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag red300">P 2</div>
              <StyledDesc3 variant="neutral_900">High Impact {"<"} 2 business hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag orange400">P 3</div>
              <StyledDesc3 variant="neutral_900">Medium Impact {"<"} 4 business hours</StyledDesc3>
            </div>
            <div className="labelTagWrapper">
              <div className="labelTag orange400">P 4</div>
              <StyledDesc3 variant="neutral_900">
                Low Impact or General Guidance or Dev Support {"<"} 1 business day
              </StyledDesc3>
            </div>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Tickets - Unlimited
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Minimum # Models by Plan - 60
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Quarterly and Annual Business Reviews - Included
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Account Management - Named CSM
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Onboarding Solutions Architect - Named Solutions Architect
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Office Hours - Yes
            </StyledDesc2>
            <StyledDesc2 variant="neutral_900" paddingBottom="pb8">
              Onboarding Support
            </StyledDesc2>
            <StyledDesc3 className="flexStart pb12" variant="neutral_900">
              <RightTick mtr="6px 16px 0 0" />
              <span>JumpStart program</span>
            </StyledDesc3>
          </div>
        </StyledFeatureWrapper>
      )}
    </>
  );
};

export default MobileHasuraCloudFeature;
