import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";

import HasuraCloudFeature from "./hasuracloudfeature";
import { Heading } from "../../globals/UIKit";
// import SelfHostedFeature from "./selfhostedfeature";
import MobileHasuraCloudFeature from "./mobilehasuracloudfeature";
import {
  StyledTogglePricingNavItem,
  StyledSubTitle2,
  StyledBody1,
  StyledDesc2,
} from "../shared/StyledTypography";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import { StyledPricingToggleWrapper } from "../shared/StyledToggleWrapper";
import { removePaddBottom, textCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

export const StyledMt500 = styled.div`
  margin-top: -400px;
  position: relative;
  z-index: 0;
  ${mq.between("md", "lg")} {
    margin-top: -300px;
  }
  @media (max-width: 767px) {
    margin-top: -260px;
  }
`;

const StyledTwoGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 140px;
  padding-top: 60px;
  &.supportPriority {
    padding-top: 16px;
    grid-gap: 40px 80px;
  }
  .mb24 {
    margin-bottom: 24px;
  }
  ul {
    margin-top: 4px;
    li {
      padding: 4px 0;
    }
  }
  ${mq.between("md", "lg")} {
    grid-gap: 70px;
    &.supportPriority {
      padding-top: 16px;
      grid-gap: 40px 60px;
    }
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    &.supportPriority {
      padding-top: 16px;
      grid-gap: 40px;
    }
    .mb24 {
      margin-bottom: 12px;
    }
  }
`;

const StyledSectionWrapperShowMobile = styled.div`
  display: none;
  ${mq.below.lg} {
    display: block;
  }
`;

const StyledSupportPriority = styled.div`
  scroll-margin-top: 100px;
`;
const FaceSmile = () => (
  <svg
    className="mb24"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 23.3334C13.3333 23.3334 15.8333 26.6667 20 26.6667C24.1666 26.6667 26.6666 23.3334 26.6666 23.3334M25 15H25.0166M15 15H15.0166M36.6666 20C36.6666 29.2048 29.2047 36.6667 20 36.6667C10.7952 36.6667 3.33331 29.2048 3.33331 20C3.33331 10.7953 10.7952 3.33337 20 3.33337C29.2047 3.33337 36.6666 10.7953 36.6666 20ZM25.8333 15C25.8333 15.4603 25.4602 15.8334 25 15.8334C24.5397 15.8334 24.1666 15.4603 24.1666 15C24.1666 14.5398 24.5397 14.1667 25 14.1667C25.4602 14.1667 25.8333 14.5398 25.8333 15ZM15.8333 15C15.8333 15.4603 15.4602 15.8334 15 15.8334C14.5397 15.8334 14.1666 15.4603 14.1666 15C14.1666 14.5398 14.5397 14.1667 15 14.1667C15.4602 14.1667 15.8333 14.5398 15.8333 15Z"
      stroke="#1E56E3"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const ZapFast = () => (
  <svg
    className="mb24"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 29.1667H5.83333M10.8333 20H3.33333M15 10.8333H6.66667M28.3333 5L17.3393 20.3917C16.8527 21.0729 16.6094 21.4136 16.6199 21.6975C16.6291 21.9448 16.7476 22.1752 16.9435 22.3264C17.1685 22.5 17.5871 22.5 18.4243 22.5H26.6667L25 35L35.9941 19.6083C36.4807 18.9271 36.724 18.5864 36.7134 18.3025C36.7043 18.0552 36.5857 17.8248 36.3898 17.6736C36.1649 17.5 35.7463 17.5 34.9091 17.5H26.6667L28.3333 5Z"
      stroke="#1E56E3"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Features = props => {
  const search = props.location.search;
  const [currentTab, setCurrentTab] = useState("cloud");

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const searchPlan = searchParams.get("plan");
    if (!!searchPlan && searchPlan === "self_hosted") {
      setCurrentTab("self-hosted");
    }
  }, [search]);

  return (
    <Fragment>
      <StyledSectionWrapper css={removePaddBottom}>
        <StyledMt500>
          <StyledContainerWrapper css={textCenter}>
            <StyledPricingToggleWrapper className="mMw450">
              <div
                className={
                  "toggleNavItemActiveBg" + (currentTab === "self-hosted" ? " toggleTabRight" : "")
                }
              ></div>
              {/* eslint-disable-next-line */}
              <StyledTogglePricingNavItem
                onClick={() => setCurrentTab("cloud")}
                className={currentTab === "cloud" ? " toggleNavItemActive" : ""}
              >
                Hasura Cloud
              </StyledTogglePricingNavItem>
              {/* eslint-disable-next-line */}
              <StyledTogglePricingNavItem
                onClick={() => setCurrentTab("self-hosted")}
                className={currentTab === "self-hosted" ? " toggleNavItemActive" : ""}
              >
                Self-hosted
              </StyledTogglePricingNavItem>
            </StyledPricingToggleWrapper>
          </StyledContainerWrapper>
          <HasuraCloudFeature currentTab={currentTab} bronzePlan={props.bronzePlan} />
        </StyledMt500>
        <StyledSectionWrapperShowMobile>
          <StyledContainerWrapper>
            <StyledSubTitle2 variant="neutral_900" fontWeight="font_bold" css={textCenter}>
              Features
            </StyledSubTitle2>
            <MobileHasuraCloudFeature currentTab={currentTab} bronzePlan={props.bronzePlan} />
          </StyledContainerWrapper>
        </StyledSectionWrapperShowMobile>
        <StyledContainerWrapper>
          <StyledTwoGridWrapper>
            <div>
              <FaceSmile />
              <StyledBody1 variant="neutral_900" paddingBottom="pb8" fontWeight="font_bold">
                Customer Success Manager
              </StyledBody1>
              <StyledDesc2 variant="neutral_900">
                A Customer Success Manager (CSM) is an advocate for customer success, helping
                customers in accomplishing their business goals, while simultaneously cultivating a
                long-term strategic partnership to ensure ongoing success.
              </StyledDesc2>
            </div>
            <div>
              <ZapFast />
              <StyledBody1 variant="neutral_900" paddingBottom="pb8" fontWeight="font_bold">
                JumpStart Program
              </StyledBody1>
              <StyledDesc2 variant="neutral_900">
                This Solutions Architect-guided program aims to assist Hasura practitioners in
                achieving their goals quickly. It includes product training, architectural guidance,
                and a personalized success plan to help identify relevant configuration options and
                milestones for API design and development objectives.
              </StyledDesc2>
            </div>
          </StyledTwoGridWrapper>
        </StyledContainerWrapper>
        <StyledSupportPriority id="support-priority-definitions">
          <StyledContainerWrapper>
            <Heading
              mt="56px"
              mb="16px"
              fontSize="24px"
              fontWeight="700"
              color="#1F2A37"
              lineHeight="1.2"
              letterSpacing="-0.01em"
            >
              Support Priority Definitions
            </Heading>
            <StyledTwoGridWrapper className="supportPriority">
              <div>
                <StyledBody1 variant="neutral_900" paddingBottom="pb8" fontWeight="font_bold">
                  Priority 1 (or Urgent)
                </StyledBody1>
                <StyledDesc2 variant="neutral_900">
                  This severity level indicates an error that is breaking existing functionality
                  where:
                  <ul className="numberOlWrapper">
                    <li>all users of a Hasura project cannot access any its services</li>
                    <li>no procedural workarounds exist AND</li>
                    <li>
                      one of the following is true:
                      <ul className="numberOlWrapper">
                        <li>users cannot login to the Hasura UI</li>
                        <li>
                          users cannot make changes to the Hasura metadata either via the UI or the
                          metadata APIs or the Hasura Command Line Interface (CLI)
                        </li>
                      </ul>
                    </li>
                  </ul>
                </StyledDesc2>
              </div>
              <div>
                <StyledBody1 variant="neutral_900" paddingBottom="pb8" fontWeight="font_bold">
                  Priority 2 (or High)
                </StyledBody1>
                <StyledDesc2 variant="neutral_900">
                  This severity level indicates an error that is breaking existing functionality:
                  <ul className="numberOlWrapper">
                    <li>that is impacting a majority of a Hasura project’s end-users</li>
                    <li>where users are able to use a Hasura project in a limited capacity</li>
                    <li>where no reasonable workaround exists</li>
                  </ul>
                </StyledDesc2>
              </div>
              <div>
                <StyledBody1 variant="neutral_900" paddingBottom="pb8" fontWeight="font_bold">
                  Priority 3 (or Medium)
                </StyledBody1>
                <StyledDesc2 variant="neutral_900">
                  This severity level indicates an error that is breaking existing functionality:
                  <ul className="numberOlWrapper">
                    <li>that is impacting a minority of a Hasura project’s end-users</li>
                    <li>
                      where users of a Hasura project can carry out their job duties with minimal
                      disruption, and
                    </li>
                    <li>where short-term or temporary workarounds are accessible</li>
                  </ul>
                </StyledDesc2>
              </div>
              <div>
                <StyledBody1 variant="neutral_900" paddingBottom="pb8" fontWeight="font_bold">
                  Priority 4 (or Low)
                </StyledBody1>
                <StyledDesc2 variant="neutral_900">
                  This severity level indicates an issue that is not breaking existing functionality
                  and is:
                  <ul className="numberOlWrapper">
                    <li>
                      a broad question related to products or instructions, like composing
                      GraphQL/REST queries/mutations/subscriptions, configuring accounts, or
                      inquiries about product setup or issues with new project setup;
                    </li>
                    <li>an issue with product documentation</li>
                    <li>a feature request</li>
                  </ul>
                </StyledDesc2>
              </div>
            </StyledTwoGridWrapper>
          </StyledContainerWrapper>
        </StyledSupportPriority>
      </StyledSectionWrapper>
    </Fragment>
  );
};

export default Features;
